import { useEffect } from "react";
import Footer from "../../comps/footer/Footer";
import Header from "../../comps/header/Header";
import Topper from "../../comps/topper/Topper";
import "./Works.css";

import OilPump from "../../assets/OilPump.jpg";
import Dock from "../../assets/Dock.jpg";

function Works() {

    useEffect(() => {
        return () => window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Topper />

            <Header />
        
            <main className="work">
                <h1 className="title">About Us</h1>
                <p className="subtitle">We are trusted partner in the world of petroleum products and derivatives. 
                With a commitment to excellence and a focus on customer satisfaction, we have become a leading name in the industry. Our journey 
                is fueled by a passion for delivering top-quality products and ensuring a seamless experience for our valued clients. Explore the 
                legacy as we continue to redefine standards in the sale of petroleum products and derivatives.
                </p>

                <div className="banner">
                    <div>
                        <div className="text">
                            <h1 className="title">Our Products</h1>
                            <p className="subtitle">we take pride in offering a diverse range of petroleum products and derivatives, 
                            including the exceptional E590. Our products are sourced and processed with utmost precision, adhering to the 
                            highest industry standards. Whether you are a business seeking reliable fuel solutions or an individual with specific 
                            petroleum needs, we have you covered.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={OilPump} alt="Oil Pump" />
                    </div>
                </div>

                <div className="banner" id="opposite">
                    <div>
                        <img src={Dock} alt="Oil Pump" />
                    </div>
                    <div>
                        <div className="text">
                            <h1 className="title">How It Works</h1>
                            <p className="subtitle">Somavedic is a straightforward and 
                            efficient process. Simply browse our user-friendly platform, select the products that meet your requirements. Our streamlined 
                            order processing ensures prompt delivery to your doorstep. Experience the convenience
                            of procuring top-notch petroleum products and derivatives with ease.
                            </p>
                        </div>
                    </div>
                </div>

            </main>

            <Footer />
        </>
    )
}

export default Works;