import "./Letter.css";

function Letter() {
    return (
        <div className="news-letter">
            <h2>Subscribe to Our Newsletter for Exclusive Updates</h2>
            <p>Join the Somadevic Company Community Today!</p>

            <form className="news-email">
                <input type="email" placeholder="Enter your email here" required />
                <button>Confirm</button>
            </form>
        </div>
    )
}

export default Letter;