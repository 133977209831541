import Footer from "../../comps/footer/Footer";
import Header from "../../comps/header/Header";
import "./Contact.css"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import Topper from "../../comps/topper/Topper";

function Contact() {

    useEffect(() => {
        return () => window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Topper />

            <Header />

            <main className="contact">
                <h1 className="title">Contact Us</h1>

                <div className="contact-body">
                    <div className="tab">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                        </div>
                        <p><span>Address:</span> First Al khail St., Jumeirah Lakes Towers Mazaya Business Avenue BB1</p>
                    </div>

                    <div className="tab">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                        </div>
                        <p><span>Phone:</span> +971 50 145 8768</p>
                    </div>

                    <div className="tab">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"/></svg>
                        </div>
                        <p><span>Email:</span> michal.kotlin@gmail.com</p>
                    </div>
                </div>

                <MyGoogleMap />

            </main>

            <Footer />
        </>
    )
}

function MyGoogleMap () {

    const center = { lat: 25.0683252, lng: 55.1450997 }

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_API_KEY,
    })

    if(!isLoaded) {
        return (<></>)
    }

    return (
        <div className="map">
            <GoogleMap 
                center={center} 
                zoom={15} 
                mapContainerStyle={{width: '100%', height: '600px'}} 
                options={{ zoomControl: false, streetView: false, mapTypeControl: false}}>
                    <Marker position={center} />
            </GoogleMap>
        </div>
    )
}

export default Contact;