import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import "./Footer.css";

function Footer() {
    return (
        <footer>
            <div className="top">
                <Link to="/" className="logo">
                    <img src={Logo} alt="Logo" />
                </Link>

                <nav>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/how-it-works">How it works</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="bottom">
                <p>©Copyright. Designed And Developed By Somadevic DMCC</p>

                <p className="w-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#859098" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                    <span>+123 123 123 123</span>
                </p>
            </div>
        </footer>
    )
}

export default Footer;