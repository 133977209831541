import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Home from "../home/Home";

function Error() {

    const navigate = useNavigate();

    useEffect(() => {
        return () => navigate("/")
    }, [navigate])

    return (
        <Home />
    )
}

export default Error;