import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import "./Header.css";

function Header() {
    return (
        <header>
            <Link to="/" className="logo">
                <img src={Logo} alt="Logo" />
            </Link>

            <nav>
                <ul>
                    <li>
                        <Link to="/how-it-works">How it works</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;