import Footer from "../../comps/footer/Footer";
import Header from "../../comps/header/Header";
import Letter from "../../comps/letter/Letter";
import Topper from "../../comps/topper/Topper";
import "./Home.css";

import Earth from "../../assets/Earth.svg";
import Support from "../../assets/Support.svg";
import { useEffect } from "react";

function Home() {

    useEffect(() => {
        return () => window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Topper />

            <Header />

            <section className="welcome-hero">
                <div className="text">
                    <h2>Take Charge of Your Trip with Premier Energy Solutions</h2>
                    <p>Release limitless Possibilities</p>
                </div>

                    <div className="cont-grid">
                        <div className="tab-body">
                            <div className="tab">
                                <img src={Earth} alt="Earth" />
                                <h3>Worldwide Service</h3>
                            </div>
                        </div>

                        <div className="tab-body">
                            <div className="tab">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 512 512"><path fill="#000000" d="M247 16v89h9c14.9 0 23 11.7 23.8 23.6.4 6-1.2 11.5-4.7 15.4-3.5 4-9.1 7-19.1 7-5 0-11.1-2.8-15.6-7.4-4.6-4.5-7.4-10.6-7.4-15.6h-18c0 11 5.2 20.9 12.6 28.4 7.5 7.4 17.4 12.6 28.4 12.6 14 0 25.4-5 32.5-13 7.2-8.1 10-18.6 9.3-28.6-1.2-17.5-13.4-35.18-32.8-39.42V16h-18zm-47.9 140.5L61.34 247h32.7l114.86-75.5-9.8-15zm113.8 0l-9.8 15L418 247h32.6l-137.7-90.5zM41 265v222h430V265H41zm38 23h18v176H79V288zm48 0h18v176h-18V288zm48 0h18v176h-18V288zm48 0h18v176h-18V288zm48 0h18v176h-18V288zm48 0h18v176h-18V288zm48 0h18v176h-18V288zm48 0h18v176h-18V288z"/></svg>
                                <h3>CARGO TRANSPORT</h3>
                            </div>
                        </div>

                        <div className="tab-body">
                            <div className="tab">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd"><path d="M3.691 10h6.309l-3-7h2l7 7h5c1.322-.007 3 1.002 3 2s-1.69 1.993-3 2h-5l-7 7h-2l3-7h-6.309l-2.292 2h-1.399l1.491-4-1.491-4h1.399l2.292 2"/></svg>
                                <h3>Airport TRANSPORT</h3>
                            </div>
                        </div>

                        <div className="tab-body">
                            <div className="tab">
                                <img src={Support} alt="Earth" />
                                <h3>Telephone Support</h3>
                            </div>
                        </div>

                        {/* <div className="tab-body">
                            <div className="tab">
                                <img src={Support} alt="Earth" />
                                <h3>Support</h3>
                            </div>
                        </div> */}
                </div>
                
            </section>

            <Letter />

            <Footer />
        </>
    )
}

export default Home;